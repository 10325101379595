import { Box, Grid, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { MainCard } from 'components/molecules/MainCard';
import { ScheduleEditPanel } from 'components/molecules/ScheduleEditPanel';
import { useAuth } from 'providers/AuthProvider';
import { useEditState } from 'providers/EditStateProvider';
import { dateToString, monthSpanWeeks, weekSpan } from 'util/date';
import { SchedulePeriod, StorageItems } from 'util/enum';

import { WeeklyBlock } from 'components/molecules/WeeklyBlock';
import { PrintedPage } from 'components/atoms/PrintedPage';
import { usePublishDateQuery } from 'hooks/usePublishDateQuery';
import { PeriodHeader } from 'components/molecules/PeriodHeader';
import { ScheduleHeader } from 'components/organisms/ScheduleHeader';
import { getStorageDate } from 'util/storage';

export const Schedule: React.FC = () => {
  const { isAdmin } = useAuth();
  const { editState } = useEditState();
  const { isInEditState } = editState;

  const [period, setPeriod] = useState<SchedulePeriod>(SchedulePeriod.Monthly);
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  const [date, setDate] = useState<Date>(
    getStorageDate(StorageItems.DisplayDate, new Date()),
  );
  const dateSpans =
    period === SchedulePeriod.Weekly ? [weekSpan(date)] : monthSpanWeeks(date);

  const { data: publishDate } = usePublishDateQuery(date);
  const showSchedule = publishDate?.data.isPublished || isInEditState;

  const setDateWithSave = (date: Date) => {
    sessionStorage.setItem('displayDate', dateToString(date));
    setDate(date);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h1" textAlign="center">
        Schedule
      </Typography>
      <Box p={2} />
      {isAdmin && <ScheduleEditPanel date={date} />}
      <MainCard style={{ minWidth: '640px' }}>
        <div ref={printRef}>
          <ScheduleHeader
            startDate={date}
            onSelectDate={setDateWithSave}
            period={period}
            onSetPeriod={setPeriod}
            onPrint={handlePrint}
          />
          <Box pb={3} />
          {showSchedule &&
            dateSpans.map((week, index) => (
              <PrintedPage key={dateToString(week[0])}>
                {index > 0 && (
                  <Box py={1}>
                    <PeriodHeader
                      startDate={week[0]}
                      period={period}
                      onSelectDate={setDateWithSave}
                    />
                  </Box>
                )}
                <WeeklyBlock week={week} />
              </PrintedPage>
            ))}
        </div>
        {showSchedule && (
          <Grid container justifyContent="center">
            <Grid item alignSelf="center" marginRight="auto" marginLeft="auto">
              <PeriodHeader
                period={period}
                startDate={date}
                onSelectDate={setDateWithSave}
              />
            </Grid>
          </Grid>
        )}
      </MainCard>
      <Box m={4} />
    </Grid>
  );
};
