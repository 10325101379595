import { useQuery } from 'react-query';
import { ScheduleConfigService } from 'openapi';

export const useVacationRequestsByPartnerQuery = (
  partnerId: number,
  isEnabled: boolean,
) =>
  useQuery(
    ['requests'],
    async () =>
      await ScheduleConfigService.scheduleConfigServiceGetVacationRequestsByPartner(
        {
          path: {
            partnerId,
          },
        },
      ),
    { enabled: isEnabled },
  );
