import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService } from 'openapi';

export const useDeleteShiftMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (shiftId: number) => {
      return ScheduleConfigService.scheduleConfigServiceDeleteShift({
        path: {
          shiftId: shiftId,
        },
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['shifts'] });
    },
  });
};
