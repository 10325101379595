import { Add, Close } from '@mui/icons-material';
import { Box, Button, Dialog, Grid, Typography, useTheme } from '@mui/material';
import { Loading } from 'components/atoms/Loading';
import { ProfileForm } from 'components/forms/ProfileForm';
import { UserForm } from 'components/forms/UserForm';
import { MainCard } from 'components/molecules/MainCard';
import { PartnerAccordion } from 'components/molecules/PartnerAccordion';
import { UserRow } from 'components/molecules/UserRow';
import { useCreateNonPartnerUserMutation } from 'hooks/useCreateNonPartnerUserMutation';
import { useCreatePartnerMutation } from 'hooks/useCreatePartnerMutation';
import { useCreateUserFromPartnerMutation } from 'hooks/useCreateUserFromPartnerMutation';
import { usePartnersQuery } from 'hooks/usePartnersQuery';
import { useSnackbar } from 'notistack';
import { AuthUser, Partner, UsersService } from 'openapi';
import { useAuth } from 'providers/AuthProvider';
import React from 'react';
import { useModal } from 'react-modal-hook';
import { useQuery } from 'react-query';

const employeeDefinitions = [
  { title: 'Partners', status: 'partner' },
  { title: 'Employees', status: 'employee' },
  { title: 'Mid-Level', status: 'midlevel' },
  { title: 'Retired', status: 'retired' },
];

export const Partners: React.FC = () => {
  const { isLoading: isLoadingPartners, data: partners } = usePartnersQuery();

  const { isLoading: isLoadingUsers, data: users } = useQuery(
    ['users'],
    UsersService.usersServiceGetUsers,
  );

  const theme = useTheme();
  const snackbar = useSnackbar();
  const { isAdmin } = useAuth();

  const defaultPartner: Partner = {
    id: 0,
    account_id: '',
    firstName: '',
    middleName: '',
    lastName: '',
    initials: '',
    birthday: '',
    email: '',
    fax: '',
    pager: '',
    status: '',
    spouseName: '',
    hireDate: '',
    endDate: '',
    partnerDate: '',
    retirementDate: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    mobile: '',
    jobTitle: '',
    shiftPreferences: undefined,
  };
  const defaultUser: AuthUser = {
    uid: '',
    displayName: '',
    email: '',
    role: '',
  };

  const createPartnerMutation = useCreatePartnerMutation();
  const createUserFromPartnerMutation = useCreateUserFromPartnerMutation();
  const createPartnerAndUser = async (partner: Partner) => {
    try {
      const newPartner = await createPartnerMutation.mutateAsync(partner);
      await createUserFromPartnerMutation.mutateAsync(newPartner.data.id);
      snackbar.enqueueSnackbar('Partner and user created successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to create partner and user', {
        variant: 'error',
      });
    }
    hideModal();
  };

  const [showModal, hideModal] = useModal(() => (
    <Dialog open={true} fullWidth maxWidth="lg">
      <Grid container justifyContent="right">
        <Button onClick={hideModal}>
          <Close />
        </Button>
      </Grid>
      <Typography variant="h2" textAlign="center">
        Create New Partner
      </Typography>
      <Box
        py={4}
        sx={{ minWidth: theme.spacing(90), maxWidth: theme.spacing(120) }}>
        <ProfileForm
          mode="modal"
          partner={defaultPartner}
          partnerUser={defaultUser}
          onSubmit={createPartnerAndUser}
          onCancel={hideModal}
        />
      </Box>
    </Dialog>
  ));

  const PartnersCard: React.FC<{ partners: Array<Partner> }> = ({
    partners,
  }) => {
    return (
      <MainCard>
        <Grid container alignItems="baseline" pl={2} pr={5} mb={2}>
          <Grid item xs={8} md={3}>
            <Typography variant="h4">Name</Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography variant="h4">Initials</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h4">Mobile</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h4">Email</Typography>
          </Grid>
          {isAdmin && (
            <Grid item xs={12} md={1}>
              <Button variant="contained" size="small" onClick={showModal}>
                <Add />
                New
              </Button>
            </Grid>
          )}
        </Grid>
        {partners.length > 0
          ? partners
              .sort((a, b) => a.initials?.localeCompare(b.initials ?? '') ?? 0)
              .map((partner) => {
                const user = users?.data.find(
                  (user) => user.uid === partner.account_id,
                );
                return user ? (
                  <PartnerAccordion
                    key={partner.id}
                    partner={partner}
                    user={user}
                  />
                ) : null;
              })
          : 'No partners found.'}
      </MainCard>
    );
  };

  const [showUserModal, hideUserModal] = useModal(() => (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Grid container justifyContent="right">
        <Button onClick={hideUserModal}>
          <Close />
        </Button>
      </Grid>
      <Typography variant="h2" textAlign="center">
        Create New User
      </Typography>
      <Box
        py={4}
        sx={{ minWidth: theme.spacing(50), maxWidth: theme.spacing(70) }}>
        <UserForm
          mode="modal"
          user={defaultUser}
          onSubmit={createNonPartnerUser}
          onCancel={hideUserModal}
        />
      </Box>
    </Dialog>
  ));

  const createNonPartnerUserMutation = useCreateNonPartnerUserMutation();
  const createNonPartnerUser = async (user: AuthUser) => {
    try {
      await createNonPartnerUserMutation.mutateAsync(user);
      snackbar.enqueueSnackbar('User created successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to create shift', {
        variant: 'error',
      });
    }
    hideUserModal();
  };

  const UsersCard: React.FC<{ users: Array<AuthUser> }> = ({ users }) => {
    return (
      <MainCard>
        <Grid container pl={2} pr={5} mb={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Name</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Email</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h4">Access</Typography>
          </Grid>
          {isAdmin && (
            <Grid item xs={12} md={1}>
              <Button variant="contained" size="small" onClick={showUserModal}>
                <Add />
                New
              </Button>
            </Grid>
          )}
        </Grid>
        {users.length > 0
          ? users.map((user) => <UserRow key={user.uid} user={user} />)
          : 'No other users found.'}
      </MainCard>
    );
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h1" textAlign="center">
        Partner Directory
      </Typography>
      <Box m={4} />
      {partners && users ? (
        employeeDefinitions
          .map((definition) => (
            <div key={definition.status}>
              <Typography variant="h2" pl={6}>
                {definition.title}
              </Typography>
              <Box m={4} />
              <PartnersCard
                key={definition.status}
                partners={partners.data.filter(
                  (partner) => partner.status === definition.status,
                )}
              />
              <Box m={6} />
            </div>
          ))
          .concat(
            <div key="other">
              <Typography variant="h2" pl={6}>
                Other Users
              </Typography>
              <Box m={4} />
              <UsersCard
                users={users.data
                  .filter(
                    (user) =>
                      !partners.data.find(
                        (partner) => partner.account_id === user.uid,
                      ),
                  )
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))}
              />
              <Box m={6} />
            </div>,
          )
      ) : isLoadingPartners || isLoadingUsers ? (
        <Loading />
      ) : (
        'No partners found.'
      )}
      <Box m={4} />
    </Grid>
  );
};
