import { AuthUser, CreateUserRequest, UsersService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useCreateNonPartnerUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: AuthUser) => {
      const requestBody: CreateUserRequest = {
        userType: user.role === 'hospital' ? 'hospital' : 'non-partner',
        displayName: user.displayName,
        email: user.email,
        partnerId: undefined,
      };
      const newUserResp = await UsersService.usersServiceCreateUser({
        body: requestBody,
      }).catch(() => null);

      if (!newUserResp?.data) {
        throw new Error('Failed to create new user');
      }

      const newUser = newUserResp.data;
      return UsersService.usersServiceSetUserRole({
        path: {
          id: newUser?.uid,
        },
        body: {
          role: user.role,
        },
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['users'] });
    },
  });
};
