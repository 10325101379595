import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService, Shift } from 'openapi';

export const useUpdateShiftMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (shift: Shift) => {
      return ScheduleConfigService.scheduleConfigServiceUpdateShift({
        path: {
          shiftId: shift.id,
        },
        body: shift,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['shifts'] });
    },
  });
};
