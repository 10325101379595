import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService } from 'openapi';
import { HolidayWithShifts } from 'pages/scheduleConfig/Holidays';

export const useUpdateHolidayMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (holiday: HolidayWithShifts) => {
      await ScheduleConfigService.scheduleConfigServiceUpdateHoliday({
        path: {
          id: holiday.id,
        },
        body: holiday,
      });
      await ScheduleConfigService.scheduleConfigServiceUpdateHolidayShifts({
        path: {
          id: holiday.id,
        },
        body: holiday.shifts,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['holidays'] });
      queryClient.refetchQueries({ queryKey: ['holidaysWithShifts'] });
      queryClient.refetchQueries({ queryKey: ['schedule'] });
    },
  });
};
