import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService, Shift } from 'openapi';

interface UpdateStandardShiftMutationParams {
  shift: Shift;
  standard: boolean;
}

export const useUpdateStandardShiftMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mutationVars: UpdateStandardShiftMutationParams) => {
      const { shift, standard } = mutationVars;
      return ScheduleConfigService.scheduleConfigServiceUpdateShift({
        path: {
          shiftId: shift.id,
        },
        body: {
          ...shift,
          standard,
        },
      });
    },
    onMutate: (mutationVars: UpdateStandardShiftMutationParams) => {
      queryClient.setQueryData(['shifts'], (shifts: Shift[] | undefined) => {
        if (shifts === undefined) {
          return [];
        }
        const shift = shifts.find(
          (shift) => shift.id === mutationVars.shift.id,
        );
        if (shift) {
          shift.standard = mutationVars.standard;
        }
        return shifts;
      });
    },
  });
};
