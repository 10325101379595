import { ScheduleConfigService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useVacationRequestDenyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (requestId: number) => {
      return ScheduleConfigService.scheduleConfigServiceDenyVacationRequest({
        path: {
          requestId,
        },
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['requests'] });
    },
  });
};
