import { Maintenance } from 'pages/Maintenance';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';

function MaintenanceApp() {
  return (
    <Routes>
      <Route path="/" element={<Maintenance />}></Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default MaintenanceApp;
