export interface ClientConfigType {
  AUTH_DOMAIN: string;
  AUTH_CLIENT_ID: string;
  MAINTENANCE_MODE: boolean;
}

const ClientConfig: ClientConfigType = {
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN || 'ev-gs4k8idk.us.auth0.com',
  AUTH_CLIENT_ID:
    process.env.REACT_APP_AUTH_CLIENT_ID || 'EIce1PtRTya8ZMsIPLesHYOKefZe0I2e',
  MAINTENANCE_MODE: Boolean(process.env.REACT_APP_MAINTENANCE_MODE) ?? false,
}

Object.freeze(ClientConfig);

export default ClientConfig;
