import { Add } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  Grid,
  Pagination,
  Typography,
  useTheme,
} from '@mui/material';
import { Loading } from 'components/atoms/Loading';
import { MainCard } from 'components/molecules/MainCard';
import { VacationRequestRow } from 'components/molecules/VacationRequestRow';
import { useVacationRequestsQuery } from 'hooks/useVacationRequestsQuery';
import { useAuth } from 'providers/AuthProvider';
import { useModal } from 'react-modal-hook';
import CloseIcon from '@mui/icons-material/Close';
import { VacationRequestForm } from 'components/forms/VacationRequestForm';
import { useCreateVacationRequestMutation } from 'hooks/useCreateVacationRequestMutation';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useVacationRequestsByPartnerQuery } from 'hooks/useVacationRequestsByPartnerQuery';

const PAGE_SIZE = 10;

export const Requests: React.FC = () => {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const { isAdmin, profile } = useAuth();
  const [page, setPage] = useState(1);

  const { isLoading: isLoadingAll, data: allRequests } =
    useVacationRequestsQuery(isAdmin);
  const { isLoading: isLoadingPartner, data: partnerRequests } =
    useVacationRequestsByPartnerQuery(profile?.id ?? 0, !isAdmin);

  const createVacationRequestMutation = useCreateVacationRequestMutation();

  const [showModal, hideModal] = useModal(() => (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Grid container justifyContent="right">
        <Button onClick={hideModal}>
          <CloseIcon />
        </Button>
      </Grid>
      <Typography variant="h2" textAlign="center">
        Create Vacation Request
      </Typography>
      <Box
        py={4}
        sx={{
          minWidth: theme.spacing(64),
          maxWidth: theme.spacing(70),
          alignSelf: 'center',
        }}>
        <VacationRequestForm
          onCancel={hideModal}
          onSubmit={async (vacationRequest) => {
            try {
              await createVacationRequestMutation.mutateAsync(vacationRequest);
              snackbar.enqueueSnackbar(
                'Created vacation request successfully',
                {
                  variant: 'success',
                },
              );
            } catch {
              snackbar.enqueueSnackbar('Failed to create vacation', {
                variant: 'error',
              });
            }
            hideModal();
          }}
        />
      </Box>
    </Dialog>
  ));

  const { pendingRequests, completedRequests } = useMemo(() => {
    const requests = allRequests?.data ?? partnerRequests?.data;
    if (!requests) {
      return {
        pendingRequests: [],
        completedRequests: [],
      };
    }

    const pending = requests.filter((request) => request.approval === 'pending');
    const completed = requests.filter((request) => request.approval !== 'pending');
    return {
      pendingRequests: pending,
      completedRequests: completed,
    };
  }, [allRequests, partnerRequests]);
  const pagedCompleted = completedRequests.slice(
    PAGE_SIZE * (page - 1),
    PAGE_SIZE * page,
  );

  const pendingRows =
    pendingRequests.length > 0
      ? pendingRequests.map((request) => (
          <VacationRequestRow key={request.id} request={request} />
        ))
      : 'No pending requests.';

  const completedRows =
    completedRequests.length > 0 ? (
      <AccordionDetails>
        {completedRequests.length > PAGE_SIZE && (
          <Pagination
            count={Math.floor(completedRequests.length / PAGE_SIZE) + 1}
            page={page}
            onChange={(event, value) => setPage(value)}
            sx={{ marginLeft: 'auto', paddingBottom: theme.spacing(2) }}
          />
        )}
        {pagedCompleted.map((request) => (
          <VacationRequestRow key={request.id} request={request} />
        ))}
      </AccordionDetails>
    ) : (
      'No completed requests.'
    );

  const columnCount = isAdmin ? 12 : 10;

  return (
    <Grid item xs={12}>
      <Typography variant="h1" textAlign="center">
        Vacation Requests
      </Typography>
      <Box m={4} />
      <MainCard>
        {allRequests || partnerRequests ? (
          <div>
            <Grid
              container
              alignItems="baseline"
              px={4}
              mb={2}
              columns={columnCount}>
              {isAdmin && (
                <Grid item xs={8} md={2}>
                  <Typography variant="h4">Initials</Typography>
                </Grid>
              )}
              <Grid item xs={8} md={4}>
                <Typography variant="h4">Dates</Typography>
              </Grid>
              <Grid item xs={8} md={1}>
                <Typography variant="h4">Type</Typography>
              </Grid>
              <Grid item xs={8} md={1}>
                <Typography variant="h4">Priority</Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <Typography variant="h4">Request Date</Typography>
              </Grid>
              <Grid item xs={8} md={1}>
                <Typography variant="h4">Approval</Typography>
              </Grid>
              <Grid item xs={8} md={1}>
                <Button variant="contained" size="small" onClick={showModal}>
                  <Add />
                  New
                </Button>
              </Grid>
            </Grid>
            {pendingRows}
            <Box pb={4} />
            <Accordion>
              <AccordionSummary>
                <Typography>Completed Requests</Typography>
              </AccordionSummary>
              {completedRows}
            </Accordion>
          </div>
        ) : isLoadingAll || isLoadingPartner ? (
          <Loading />
        ) : (
          'Requests not found'
        )}
      </MainCard>
    </Grid>
  );
};
