import { Loading } from 'components/atoms/Loading';
import { MainCard } from 'components/molecules/MainCard';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Dialog,
  useTheme,
} from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { ShiftAccordion } from 'components/molecules/ShiftAccordion';
import { Add, Close } from '@mui/icons-material';
import { ShiftForm } from 'components/forms/ShiftForm';
import { useModal } from 'react-modal-hook';
import { useCreateShiftMutation } from 'hooks/useCreateShiftMutation';
import { useSnackbar } from 'notistack';
import { useShiftsQuery } from 'hooks/useShiftsQuery';
import { Droppable } from 'components/atoms/StrictModeDroppable';
import { useShiftRerankMutation } from 'hooks/useShiftRerankMutation';
import { useAuth } from 'providers/AuthProvider';
import { Shift } from 'openapi';

export const Shifts: React.FC = () => {
  const { isLoading: isLoadingShifts, data: shifts } = useShiftsQuery();
  const rerankMutation = useShiftRerankMutation();
  const { isAdmin } = useAuth();

  const onDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }
    const destination = dropResult.destination;
    const startIndex = dropResult.source.index;
    const endIndex = destination.index;

    if (shifts) {
      rerankMutation.mutateAsync({
        startIndex,
        endIndex,
        subjectShiftId: shifts[startIndex].id,
        targetShiftId: shifts[endIndex].id,
      });
    }
  };
  const theme = useTheme();
  const snackbar = useSnackbar();

  const defaultShift: Shift = {
    id: 0,
    description: '',
    rank: 0,
    onCall: false,
    deleted: false,
    standard: true,
    monday: false,
    mondaypoints: 0,
    mondaycutpoints: 0,
    tuesday: false,
    tuesdaypoints: 0,
    tuesdaycutpoints: 0,
    wednesday: false,
    wednesdaypoints: 0,
    wednesdaycutpoints: 0,
    thursday: false,
    thursdaypoints: 0,
    thursdaycutpoints: 0,
    friday: false,
    fridaypoints: 0,
    fridaycutpoints: 0,
    saturday: false,
    saturdaypoints: 0,
    saturdaycutpoints: 0,
    sunday: false,
    sundaypoints: 0,
    sundaycutpoints: 0,
  };

  const shiftCreateMutation = useCreateShiftMutation();
  const createShift = async (shift: Shift) => {
    try {
      await shiftCreateMutation.mutateAsync(shift);
      snackbar.enqueueSnackbar('Shift created successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to create shift', {
        variant: 'error',
      });
    }
    hideModal();
  };

  const [showModal, hideModal] = useModal(() => (
    <Dialog open={true} fullWidth maxWidth="md">
      <Grid container justifyContent="right">
        <Button onClick={hideModal}>
          <Close />
        </Button>
      </Grid>
      <Typography variant="h2" textAlign="center">
        Create New Shift
      </Typography>
      <Box
        py={4}
        sx={{ minWidth: theme.spacing(90), maxWidth: theme.spacing(120) }}>
        <ShiftForm
          mode="modal"
          shift={defaultShift}
          onSubmit={createShift}
          onCancel={hideModal}
        />
      </Box>
    </Dialog>
  ));

  return (
    <Grid item xs={12}>
      <Typography variant="h1" textAlign="center">
        Edit Shifts
      </Typography>
      <Box m={4} />
      <MainCard>
        <Grid container alignItems="baseline" pl={2} pr={5} mb={2}>
          <Grid item xs={2} md={1}>
            <Typography variant="h4">Std</Typography>
          </Grid>
          <Grid item xs={4} md={5}>
            <Typography variant="h4">Name</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant="h4">Points</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography variant="h4">Days</Typography>
          </Grid>
          <Grid item xs={2} md={1}>
            <Button variant="contained" size="small" onClick={showModal}>
              <Add />
              New
            </Button>
          </Grid>
          <Grid item xs={2} md={1}>
            <Box />
          </Grid>
        </Grid>
        {shifts ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="shifts">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {shifts.map((shift, index) => {
                    const idString = shift.id.toString();
                    return (
                      <Draggable
                        key={idString}
                        isDragDisabled={!isAdmin}
                        draggableId={idString}
                        index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ ...provided.draggableProps.style }}>
                            <ShiftAccordion shift={shift} />
                            <Divider />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : isLoadingShifts ? (
          <Loading />
        ) : (
          'Shifts not found.'
        )}
      </MainCard>
      <Box m={6} />
    </Grid>
  );
};
