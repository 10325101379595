import { Box, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';

import { ProfileForm } from 'components/forms/ProfileForm';
import { MainCard } from 'components/molecules/MainCard';
import { AuthUser, Partner, PartnersService, UsersService } from 'openapi';
import { Loading } from 'components/atoms/Loading';
import { useAuth } from 'providers/AuthProvider';
import { useSnackbar } from 'notistack';
import { useUpdatePartnerMutation } from 'hooks/useUpdatePartnerMutation';

export const Profile: React.FC = () => {
  const { authUser, profile } = useAuth();
  const snackbar = useSnackbar();

  const { isLoading, data: partner } = useQuery(
    ['partner', profile?.id],
    () =>
      PartnersService.partnersServiceGetPartner({
        path: {
          id: profile?.id ?? 22,
        },
      }),
    { enabled: profile !== null },
  );

  const partnerMutatation = useUpdatePartnerMutation();
  const userMutation = useMutation((authUser: AuthUser) => {
    return UsersService.usersServiceUpdateUser({
      path: {
        id: authUser.uid,
      },
      body: authUser,
    });
  });

  const updateProfileAndUser = async (partner: Partner) => {
    try {
      if (!partner || !authUser) {
        throw Error('Partner or user object not found');
      }

      await Promise.all([
        partnerMutatation.mutateAsync(partner),
        userMutation.mutateAsync({
          uid: authUser.uid,
          displayName: `${partner.firstName} ${partner.lastName}`,
          email: partner.email,
          role: authUser.role,
        }),
      ]);

      snackbar.enqueueSnackbar('Profile updated successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Profile failed to update', {
        variant: 'error',
      });
    }
  };

  return (
    <Grid item xs={12} lg={6}>
      <Typography variant="h1">Profile</Typography>
      <Box m={4} />
      <MainCard>
        {partner && authUser ? (
          <ProfileForm
            partner={partner.data}
            partnerUser={authUser}
            onSubmit={updateProfileAndUser}
          />
        ) : isLoading ? (
          <Loading />
        ) : (
          'No profile is associated with this login.'
        )}
      </MainCard>
      <Box m={4} />
    </Grid>
  );
};
