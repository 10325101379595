import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const SimpleLayout: React.FC<{children: React.ReactNode}> = ({children}) => (
  <main>
    <Container>
      <Box p={5}>
        {children}
      </Box>
    </Container>
  </main>
);
