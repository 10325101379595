import { useMutation, useQueryClient } from 'react-query';

import { DocumentLink, DocumentsService } from 'openapi';

export const useCreateDocumentLinkMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (documentLink: DocumentLink) => {
      await DocumentsService.documentsServiceCreateDocumentLink({
        body: documentLink,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['documentLinks'] });
    },
  });
};
