import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  useTheme,
} from '@mui/material';
import { usePartnersQuery } from 'hooks/usePartnersQuery';
import { usePublishMutation } from 'hooks/usePublishMutation';
import { useSnackbar } from 'notistack';
import { useEditState } from 'providers/EditStateProvider';
import { useRef } from 'react';
import { convertDateFromUTC, monthLimits } from 'util/date';

export interface ScheduleEditPanelProps {
  date: Date;
}

type HighlightOption = {
  label: string;
  id: number | undefined;
};

const NoHighlight: HighlightOption = {
  label: '(none)',
  id: undefined,
};

export const ScheduleEditPanel: React.FC<ScheduleEditPanelProps> = ({
  date,
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const { editState, highlightedPartner, setEditState, setHighlightedPartner } =
    useEditState();
  const { isInEditState } = editState;
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: partners } = usePartnersQuery();
  const publishMutation = usePublishMutation();

  const partnerOptions: Array<HighlightOption> = [NoHighlight].concat(
    partners?.data
      .filter(
        (partner) =>
          (partner.status === 'partner' || partner.status === 'employee') &&
          (!partner.endDate ||
            convertDateFromUTC(new Date(partner.endDate)) > date),
      )
      .map((partner) => ({
        label: partner.initials ?? '',
        id: partner.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
  );

  const selectedPartner =
    partnerOptions.find((partner) => partner.id === highlightedPartner) ?? null;

  const [firstOfMonth, lastOfMonth] = monthLimits(date);

  return (
    <Grid container display="flex" justifyContent="space-between">
      <Grid item pl={2} py={1}>
        <FormControlLabel
          control={
            <Switch
              checked={isInEditState}
              id="editMode"
              onChange={() => {
                setEditState(!isInEditState, firstOfMonth, lastOfMonth);
              }}
            />
          }
          label="Edit"
          labelPlacement="start"
        />
        <FormControlLabel
          disabled={!isInEditState}
          control={
            <Autocomplete
              options={partnerOptions}
              value={selectedPartner}
              id="partnerSelect"
              autoComplete={true}
              clearOnEscape
              onChange={(event, value) => {
                if (value !== undefined) {
                  setHighlightedPartner(value?.id);
                }
              }}
              ListboxProps={{ style: { maxHeight: '10rem' } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  inputRef={inputRef}
                  size="small"
                  sx={{ minWidth: theme.spacing(18), pl: 1 }}
                />
              )}
            />
          }
          label="Highlight"
          labelPlacement="start"
        />
      </Grid>
      <Grid item pr={2} py={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            if (!lastOfMonth) {
              return;
            }
            try {
              await publishMutation.mutateAsync(lastOfMonth);
              snackbar.enqueueSnackbar('Schedule published successfully', {
                variant: 'success',
              });
            } catch {
              snackbar.enqueueSnackbar('Partner failed to update', {
                variant: 'error',
              });
            }
          }}
          disabled={!isInEditState}>
          Publish
        </Button>
      </Grid>
    </Grid>
  );
};
