import { useQuery } from 'react-query';
import { ScheduleConfigService } from 'openapi';

export const useHolidaysQuery = (holidayYear: number) =>
  useQuery(['holidays', holidayYear], () =>
    ScheduleConfigService.scheduleConfigServiceGetHolidays({
      path: {
        year: holidayYear,
      },
    }),
  );
