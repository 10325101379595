import { Box, Grid, Typography } from '@mui/material';
import { MainCard } from 'components/molecules/MainCard';
import { SimpleLayout } from 'components/templates/SimpleLayout';

export const Maintenance: React.FC = () => {
  return (
    <SimpleLayout>
      <Grid item xs={12}>
        <Typography variant="h1">Maintenance</Typography>
        <Box m={4} />
        <MainCard>
          This application is currently undergoing maintenance.
          Please try back later.
        </MainCard>
        <Box m={4} />
      </Grid>
    </SimpleLayout>
  );
};
