import { Box, Dialog, Grid, Typography } from '@mui/material';
import { EditShiftForm } from 'components/forms/EditShiftForm';
import {
  addDays,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
} from 'date-fns';
import { ScheduleConfigService, ScheduleService, Shift } from 'openapi';
import { useQuery } from 'react-query';
import { convertDateFromUTC, dateToString } from 'util/date';

export interface EditShiftDialogProps {
  monday: Date;
  slotId: number;
  onClose: () => void;
}

export const EditShiftDialog: React.FC<EditShiftDialogProps> = ({
  monday,
  slotId,
  onClose,
}) => {
  const { data: shiftAsScheduled } = useQuery(
    ['shiftUseByInterval', slotId, monday],
    async () => {
      const fromDate = monday;
      const toDate = addDays(monday, 6);
      const assignments = await ScheduleService.scheduleServiceGetSchedule({
        query: {
          fromDate: dateToString(fromDate),
          toDate: dateToString(toDate),
          includeUnpublished: true,
        },
      });

      const shift = (
        await ScheduleConfigService.scheduleConfigServiceGetShifts()
      )?.data.find((shift) => shift.id === slotId);

      if (!shift) {
        return null;
      }

      const combinedShift: Shift = {
        ...shift,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };

      assignments.data.dates.forEach((workday) => {
        const shiftInstances = workday.assignments.filter(
          (assignment) => assignment.shiftId === slotId,
        );
        shiftInstances.forEach((assignment) => {
          const dateObject = convertDateFromUTC(new Date(assignment.date));
          if (isMonday(dateObject)) {
            combinedShift.monday = true;
            if (assignment.points) {
              combinedShift.mondaypoints = assignment.points;
            }
          } else if (isTuesday(dateObject)) {
            combinedShift.tuesday = true;
            if (assignment.points) {
              combinedShift.tuesdaypoints = assignment.points;
            }
          } else if (isWednesday(dateObject)) {
            combinedShift.wednesday = true;
            if (assignment.points) {
              combinedShift.wednesdaypoints = assignment.points;
            }
          } else if (isThursday(dateObject)) {
            combinedShift.thursday = true;
            if (assignment.points) {
              combinedShift.thursdaypoints = assignment.points;
            }
          } else if (isFriday(dateObject)) {
            combinedShift.friday = true;
            if (assignment.points) {
              combinedShift.fridaypoints = assignment.points;
            }
          } else if (isSaturday(dateObject)) {
            combinedShift.saturday = true;
            if (assignment.points) {
              combinedShift.saturdaypoints = assignment.points;
            }
          } else if (isSunday(dateObject)) {
            combinedShift.sunday = true;
            if (assignment.points) {
              combinedShift.sundaypoints = assignment.points;
            }
          }
        });
      });

      return combinedShift;
    },
  );

  if (!shiftAsScheduled) {
    return null;
  }

  return (
    <Dialog
      open={true}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Grid container p={4}>
        <Typography variant="h4">{`Edit ${
          shiftAsScheduled.description
        } for week of ${dateToString(monday)}`}</Typography>
        <Box p={3} />
        <EditShiftForm
          monday={monday}
          shift={shiftAsScheduled}
          onClose={onClose}
        />
      </Grid>
    </Dialog>
  );
};
