import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Loading } from 'components/atoms/Loading';
import { None, Same, PartnerSelect } from 'components/atoms/PartnerSelect';
import { format } from 'date-fns';
import { useScheduleQuery } from 'hooks/useScheduleQuery';
import { Assignment, NewAssignment, Shift } from 'openapi';
import {
  PartnerSelectOption,
  PartnerSelectProvider,
  usePartnerSelect,
} from 'providers/PartnerSelectProvider';
import { dateToString } from 'util/date';
import { emdash } from 'util/unicode';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useAssignmentMutation } from 'hooks/useAssignmentMutation';
import { usePartnersQuery } from 'hooks/usePartnersQuery';
import { ShiftSelectAdd } from 'components/organisms/ShiftSelectAdd';
import { selectPointsFromShift } from 'util/shiftAssignments';
import { useNewAssignmentMutation } from 'hooks/useNewAssignmentMutation';
import { useShiftsQuery } from 'hooks/useShiftsQuery';

const dashSame = (
  workedPartnerName: string | undefined,
  assignedPartnerName: string | undefined,
) => {
  if (workedPartnerName === undefined) {
    return emdash;
  }
  if (workedPartnerName === assignedPartnerName) {
    return '+';
  }
  return workedPartnerName;
};

interface EditAssignmentRowProps {
  assignment: Assignment;
}

const EditAssignmentRow: React.FC<EditAssignmentRowProps> = ({
  assignment,
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const assignmentMutation = useAssignmentMutation();

  const [inEditPoints, setEditPoints] = useState<boolean>(false);

  const onCheckCut = async (
    event: ChangeEvent<HTMLInputElement>,
    assignment: Assignment,
  ) => {
    assignment.cut = event.target.checked;
    try {
      await assignmentMutation.mutateAsync(assignment);
    } catch {
      snackbar.enqueueSnackbar('Assignment failed to update', {
        variant: 'error',
      });
    }
  };

  const onChangePoints = async (value: string, assignment: Assignment) => {
    const points = parseInt(value);
    if (Number.isNaN(points) || assignment.points === points) {
      return;
    }

    assignment.points = points;
    try {
      await assignmentMutation.mutateAsync(assignment);
    } catch {
      snackbar.enqueueSnackbar('Assignment failed to update', {
        variant: 'error',
      });
    }
  };

  const partnerSelect = usePartnerSelect();
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      key={assignment.shiftSymbol}>
      <Grid item xs={3}>
        <Typography>{assignment.shiftSymbol}</Typography>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Typography>{assignment.partnerNickname}</Typography>
      </Grid>
      <Grid
        item
        xs={3}
        textAlign="center"
        sx={{ '&:hover': { cursor: 'pointer' } }}
        onClick={(event: MouseEvent) => {
          partnerSelect.startSelect(
            event.currentTarget,
            assignment.date,
            assignment,
          );
        }}>
        <Typography>
          {dashSame(
            assignment.workedPartnerNickname,
            assignment.partnerNickname,
          )}
        </Typography>
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <Checkbox
          checked={assignment.cut}
          onChange={(event) => onCheckCut(event, assignment)}
        />
      </Grid>
      <Grid item xs={2} textAlign="center" onClick={() => setEditPoints(true)}>
        {inEditPoints ? (
          <ClickAwayListener onClickAway={() => setEditPoints(false)}>
            <OutlinedInput
              size="small"
              defaultValue={assignment.points}
              onKeyDown={(event) => {
                if (event.key === 'Escape') {
                  // prevent it from killing the modal
                  event.stopPropagation();
                  setEditPoints(false);
                } else if (event.key === 'Tab' || event.key === 'Enter') {
                  onChangePoints(event.currentTarget.value, assignment);
                  setEditPoints(false);
                }
              }}
              onBlur={(event) => {
                onChangePoints(event.currentTarget.value, assignment);
                setEditPoints(false);
              }}
              sx={{ width: theme.spacing(6) }}
            />
          </ClickAwayListener>
        ) : (
          <span>{assignment.points}</span>
        )}
      </Grid>
    </Grid>
  );
};

export interface EditWorkedProps {
  date: Date;
  hideModal: () => void;
}

export const EditWorked: React.FC<EditWorkedProps> = ({ date, hideModal }) => {
  const theme = useTheme();

  const atDate = dateToString(date);
  const { isLoading, data: schedule } = useScheduleQuery(atDate, atDate, true);

  const snackbar = useSnackbar();

  const assignmentMutation = useAssignmentMutation();
  const newAssignmentMutation = useNewAssignmentMutation();

  const { data: partners } = usePartnersQuery();
  const { data: shifts } = useShiftsQuery();

  const partnerOptions = [Same].concat(
    partners?.data
      .filter(
        (partner) =>
          (partner.status === 'partner' || partner.status === 'employee') &&
          !partner.endDate,
      )
      .map((partner) => ({
        label: partner.initials ?? '',
        id: partner.id,
        group: '',
      })) ?? [],
  );

  const assignments = useMemo(() => {
    if (!schedule || !shifts || !schedule.assignments[atDate]) {
      return Array<Assignment>();
    }

    const assignments: Array<Assignment> = [];
    Object.values(schedule.assignments[atDate]).forEach((shift) =>
      assignments.push(...shift),
    );
    return assignments
      .map((assignment) => {
        const shift = shifts.find((shift) => shift.id === assignment.shiftId);
        return {
          ...assignment,
          rank: shift?.rank ?? 1000000,
        };
      })
      .sort((a, b) => a.rank - b.rank);
  }, [schedule, shifts]);

  const shiftsInUse = assignments.map((assignment) => assignment.shiftId);

  const getPartnersAvailable = (date: string) => {
    if (!schedule) {
      return partnerOptions;
    }

    // figure out who no longer works on this date
    const terminatedPartnerIds =
      partners?.data
        .filter((partner) => partner.endDate && partner.endDate < date)
        .map((partner) => partner.id) ?? [];

    // figure out partners in unchanged assignments
    const assignedPartnerIds: Array<number> = [];
    for (const slot in schedule.assignments[date]) {
      if (schedule.assignments[date][slot]) {
        const unchangedAssignments = schedule.assignments[date][slot].filter(
          (assignment) => assignment.workedPartnerId !== assignment.partnerId,
        );
        const nontrivial = unchangedAssignments
          .map((assignment) => assignment.workedPartnerId)
          .filter((id) => Boolean(id)) as Array<number>;
        assignedPartnerIds.push(...nontrivial);
      }
    }

    // figure out who is on vacation
    const vacationPartnerIds: Array<number> = [];
    for (const slot in schedule.vacations[date]) {
      if (schedule.vacations[date][slot]) {
        const nontrivial = schedule.vacations[date][slot]
          .map((vacation) => vacation.partnerId)
          .filter((id) => Boolean(id)) as Array<number>;
        vacationPartnerIds.push(...nontrivial);
      }
    }

    const unassigned = partnerOptions
      .filter(
        (partner) =>
          !terminatedPartnerIds.includes(partner.id) &&
          !assignedPartnerIds.includes(partner.id) &&
          !vacationPartnerIds.includes(partner.id),
      )
      .sort((a, b) => a.label.localeCompare(b.label));
    const vacation = partnerOptions
      .filter((partner) => vacationPartnerIds.includes(partner.id))
      .map((partner) => ({ ...partner, group: 'Vacation' }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return unassigned.concat(vacation);
  };

  const finishSelect = async (
    value: PartnerSelectOption | null,
    date: string,
    assignment?: Assignment,
  ) => {
    if (assignment && value) {
      if (value === Same) {
        assignment.workedPartnerId = assignment.partnerId;
        assignment.workedPartnerNickname = assignment.partnerNickname;
      } else if (value === None) {
        assignment.workedPartnerId = undefined;
        assignment.workedPartnerNickname = undefined;
      } else {
        assignment.workedPartnerId = value.id;
        assignment.workedPartnerNickname = value.label;
      }

      try {
        await assignmentMutation.mutateAsync(assignment);
      } catch {
        snackbar.enqueueSnackbar('Assignment failed to update', {
          variant: 'error',
        });
      }
    }
  };

  const onAddShift = async (shift: Shift) => {
    const assignment: NewAssignment = {
      date: atDate,
      shiftId: shift.id,
      points: selectPointsFromShift(date, shift),
      published: true,
    };

    try {
      await newAssignmentMutation.mutateAsync(assignment);
    } catch {
      snackbar.enqueueSnackbar('Failed to add shift', {
        variant: 'error',
      });
    }
  };

  return (
    <Paper
      elevation={4}
      sx={{ minWidth: theme.spacing(60), maxWidth: theme.spacing(100) }}>
      <Grid
        item
        display="flex"
        justifyContent="right"
        height={theme.spacing(6)}>
        <Button onClick={hideModal}>
          <Close />
        </Button>
      </Grid>
      <Box px={6} pt={1} pb={2}>
        <Typography variant="h2" textAlign="center">
          Edit Schedule
        </Typography>
        <Typography variant="h3" textAlign="center">
          {format(date, 'MMM d, y')}
        </Typography>
        <Box p={2} />
        {schedule ? (
          <PartnerSelectProvider
            getPartnersAvailable={getPartnersAvailable}
            finishSelect={finishSelect}>
            <Grid container spacing={2} alignItems="center" pl={1} pb={2}>
              <Grid item xs={3}>
                <Typography variant="h4">Shift</Typography>
              </Grid>
              <Grid item xs={3} textAlign="center">
                <Typography variant="h4">Assigned</Typography>
              </Grid>
              <Grid item xs={3} textAlign="center">
                <Typography variant="h4">Worked</Typography>
              </Grid>
              <Grid item xs={1} display="flex" justifyContent="center">
                <Typography variant="h4">Cut</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h4">Points</Typography>
              </Grid>
            </Grid>
            <Box
              sx={{ maxHeight: theme.spacing(60), overflowY: 'scroll' }}
              border={2}
              borderColor={theme.palette.divider}
              mr={-3}>
              <Box p={1}>
                {assignments.map((assignment, index) => (
                  <EditAssignmentRow key={index} assignment={assignment} />
                ))}
                <Box width={theme.spacing(25)}>
                  <ShiftSelectAdd
                    shiftsToExclude={shiftsInUse}
                    onSelectShift={(shift) => onAddShift(shift)}
                  />
                </Box>
              </Box>
            </Box>
            <PartnerSelect value={null} useWithinDialog={true} />
          </PartnerSelectProvider>
        ) : isLoading ? (
          <Loading />
        ) : (
          'An error occured while loading the schedule.'
        )}
        <Box p={1} />
        <Grid display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            onClick={hideModal}
            sx={{ width: theme.spacing(10) }}>
            Close
          </Button>
        </Grid>
      </Box>
    </Paper>
  );
};
