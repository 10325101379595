import { Partner, PartnersService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdatePartnerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (partner: Partner) => {
      await PartnersService.partnersServiceUpdatePartner({
        path: {
          id: partner.id,
        },
        body: partner,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['partners'] });
      queryClient.refetchQueries({ queryKey: ['partner'] });
    },
  });
};
