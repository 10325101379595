import { ScheduleService } from 'openapi';
import { useQuery } from 'react-query';
import { emdash } from 'util/unicode';

export const usePartnerScheduleQuery = (
  profileId: number | undefined,
  fromDate: string,
  toDate: string,
) =>
  useQuery(
    ['partnerSchedule', fromDate, toDate],
    async () => {
      // it's possible to not have a profileId, in which case this query is disabled below
      const schedule = await ScheduleService.scheduleServiceGetPartnerSchedule({
        path: {
          partnerId: profileId ?? 0,
        },
        query: {
          fromDate,
          toDate,
        },
      });
      const assignments: Record<string, string> = {};
      schedule.data.dates.forEach((workday) => {
        if (workday.date) {
          assignments[workday.date] = workday.symbol ?? emdash;
        }
      });

      return assignments;
    },
    { enabled: !!profileId },
  );
