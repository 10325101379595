import { useMutation, useQueryClient } from 'react-query';

import { DocumentsService, DocumentLink } from 'openapi';

export const useUpdateDocumentLinkMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (documentLink: DocumentLink) => {
      return DocumentsService.documentsServiceUpdateDocumentLink({
        path: {
          id: documentLink.id,
        },
        body: documentLink,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['documentLinks'] });
    },
  });
};
