import { DocumentsService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteDocmentLinkMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (documentLinkId: number) => {
      return DocumentsService.documentsServiceDeleteDocumentLink({
        path: {
          id: documentLinkId,
        },
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['documentLinks'] });
    },
  });
};
