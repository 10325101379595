import { DocumentsService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (path: string) => {
      return DocumentsService.documentsServiceDeleteDocument({
        body: { path },
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['documents'] });
    },
  });
};
