import { ReportsService, ShiftsReportConfig } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useParnterShiftsConfigMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (shiftConfig: ShiftsReportConfig) => {
      return ReportsService.reportsServiceUpdateShiftTotalsConfig({
        body: shiftConfig,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['partnerShiftsConfig'] });
    },
  });
};
