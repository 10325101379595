import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService, VacationRequest } from 'openapi';

export const useCreateVacationRequestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (vacationRequest: VacationRequest) => {
      return ScheduleConfigService.scheduleConfigServiceCreateVacationRequest({
        body: vacationRequest,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['requests'] });
    },
  });
};
