import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FormLabel,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { AddShiftForm } from 'components/forms/AddShiftForm';
import { useMediaSize } from 'hooks/useMediaSize';
import { useShiftsQuery } from 'hooks/useShiftsQuery';
import { ScheduleService, Shift } from 'openapi';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { dateToString } from 'util/date';

export interface AddShiftDialogProps {
  monday: Date;
  onClose: () => void;
}

export const AddShiftDialog: React.FC<AddShiftDialogProps> = ({
  monday,
  onClose,
}) => {
  const theme = useTheme();
  const { data: shiftsInUse } = useQuery(['shiftsByDate', monday], async () => {
    const dateString = dateToString(monday);
    const assignments = await ScheduleService.scheduleServiceGetSchedule({
      query: {
        fromDate: dateString,
        toDate: dateString,
        includeUnpublished: true,
      },
    });
    return assignments.data.dates[0].assignments.map(
      (assignment) => assignment.shiftId,
    );
  });

  const { data: shifts } = useShiftsQuery();

  const [shiftsAvailable, setShiftsAvailable] = useState<Shift[]>([]);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);

  useEffect(() => {
    const shiftsAvailable =
      shiftsInUse && shifts
        ? shifts
            .filter((shift) => !shiftsInUse.includes(shift.id))
            .sort((a, b) => a.rank - b.rank)
        : [];
    setShiftsAvailable(shiftsAvailable);

    if (!selectedShift && shiftsAvailable.length > 0) {
      setSelectedShift(shiftsAvailable[0]);
    }
  }, [shiftsInUse, shifts, selectedShift]);

  const shiftSelections = shiftsAvailable.map((shift) => ({
    id: shift.id,
    label: shift.description,
  }));
  const selectedShiftValue =
    shiftSelections.find(
      (shiftSelection) => shiftSelection.id === selectedShift?.id,
    ) ?? null;

  const inputRef = useRef<HTMLInputElement>(null);

  const mediaSize = useMediaSize();
  const labelAlign = mediaSize === 'XS' ? 'left' : 'center';

  return (
    <Dialog
      open={true}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Grid
        item
        display="flex"
        justifyContent="right"
        height={theme.spacing(6)}>
        <Button onClick={onClose}>
          <Close />
        </Button>
      </Grid>
      <Grid container px={4} pb={4}>
        <Typography variant="h4">Please select a shift to add</Typography>
        <Box p={3} />
        <Grid container item columns={10} alignItems="center">
          <Grid item xs={12} sm={2} textAlign={labelAlign}>
            <FormLabel>Shift</FormLabel>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              options={shiftSelections}
              value={selectedShiftValue}
              autoComplete={true}
              clearOnEscape
              onChange={(event, value) => {
                const shift =
                  shifts?.find((shift) => shift.id === value?.id) ?? null;
                setSelectedShift(shift);
              }}
              ListboxProps={{ style: { maxHeight: '10rem' } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  inputRef={inputRef}
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
        <Box p={2} />
        {selectedShift && (
          <AddShiftForm
            monday={monday}
            shift={selectedShift}
            onClose={onClose}
          />
        )}
      </Grid>
    </Dialog>
  );
};
