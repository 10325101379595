import { useMutation, useQueryClient } from 'react-query';

import { ScheduleService } from 'openapi';
import { dateToString } from 'util/date';

export interface CreateScheduleMutationParams {
  beginDate: Date;
  endDate: Date;
}

export const useCreateScheduleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: CreateScheduleMutationParams) => {
      const { beginDate, endDate } = params;
      return ScheduleService.scheduleServiceCreateSchedule({
        query: {
          fromDate: dateToString(beginDate),
          toDate: dateToString(endDate),
        },
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['schedule'] });
    },
  });
};
