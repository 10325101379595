import { UsersService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

interface UpdateUserRoleMutationParams {
  userId: string;
  role: string;
}

export const useUpdateUserRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mutationVars: UpdateUserRoleMutationParams) =>
      UsersService.usersServiceSetUserRole({
        path: {
          id: mutationVars.userId,
        },
        body: {
          role: mutationVars.role,
        },
      }),
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['users'] });
    },
  });
};
