import { LocalPrintshop } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { PeriodHeader } from 'components/molecules/PeriodHeader';
import { useCreateScheduleMutation } from 'hooks/useCreateScheduleMutation';
import { usePublishDateQuery } from 'hooks/usePublishDateQuery';
import { useSnackbar } from 'notistack';
import { SchedulePeriod } from 'util/enum';
import { MouseEvent } from 'react';
import { useEditState } from 'providers/EditStateProvider';
import { dateToString, monthLimits } from 'util/date';
import { Loading } from 'components/atoms/Loading';
import { ScreenOnly } from 'components/atoms/ScreenOnly';
import { PrintOnly } from 'components/atoms/PrintOnly';

export interface ScheduleHeaderProps {
  period: SchedulePeriod;
  onSetPeriod: (period: SchedulePeriod) => void;
  startDate: Date;
  onSelectDate: (date: Date) => void;
  onPrint?: () => void;
}

export const ScheduleHeader: React.FC<ScheduleHeaderProps> = ({
  period,
  onSetPeriod,
  startDate,
  onSelectDate,
  onPrint,
}) => {
  const snackbar = useSnackbar();
  const [firstOfMonth, lastOfMonth] = monthLimits(startDate);
  const { isLoading: isPublishedLoading, data: publishDate } =
    usePublishDateQuery(lastOfMonth);

  const createScheduleMutation = useCreateScheduleMutation();
  const createSchedule = async () => {
    try {
      await createScheduleMutation.mutateAsync({
        beginDate: firstOfMonth,
        endDate: lastOfMonth,
      });
      snackbar.enqueueSnackbar('Successfully created schedule', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to create schedule', {
        variant: 'error',
      });
    }
  };

  const changePeriod = (_: MouseEvent<HTMLElement>, value: SchedulePeriod) => {
    if (value !== null) {
      onSetPeriod(value);
    }
  };

  const { editState } = useEditState();
  const { isInEditState } = editState;

  const canCreateSchedule = isInEditState && !publishDate?.data.date;

  const publishStatus = publishDate?.data.date
    ? `Last published: ${dateToString(new Date(publishDate.data.date))}`
    : 'This schedule has not yet been published';

  return (
    <>
      <ScreenOnly>
        <Grid container justifyContent="center">
          <Grid item>
            <Box minWidth={200} />
          </Grid>
          <Grid item alignSelf="center" marginRight="auto" marginLeft="auto">
            <PeriodHeader
              period={period}
              startDate={startDate}
              onSelectDate={onSelectDate}
            />
            {isPublishedLoading ? (
              <Loading />
            ) : (
              <Stack spacing={2}>
                <Typography variant="subtitle1" textAlign="center">
                  {publishStatus}
                </Typography>
                {canCreateSchedule && (
                  <Button variant="contained" onClick={() => createSchedule()}>
                    Create schedule
                  </Button>
                )}
              </Stack>
            )}
          </Grid>
          <Grid item width={200}>
            <ToggleButtonGroup
              size="small"
              color="primary"
              exclusive
              value={period}
              onChange={changePeriod}>
              <ToggleButton value={SchedulePeriod.Monthly}>
                Monthly
              </ToggleButton>
              <ToggleButton value={SchedulePeriod.Weekly}>Weekly</ToggleButton>
            </ToggleButtonGroup>
            {onPrint && (
              <Button onClick={onPrint}>
                <LocalPrintshop />
              </Button>
            )}
          </Grid>
        </Grid>
      </ScreenOnly>
      <PrintOnly>
        <Grid item alignSelf="center" marginRight="auto" marginLeft="auto">
          <PeriodHeader
            period={period}
            startDate={startDate}
            onSelectDate={onSelectDate}
          />
          <Typography variant="subtitle1" textAlign="center">
            {publishStatus}
          </Typography>
        </Grid>
      </PrintOnly>
    </>
  );
};
