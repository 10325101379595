import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from 'firebase/auth';

import { UsersService } from 'openapi/sdk.gen';
import { Partner } from 'openapi/types.gen';
import { AuthUser } from 'openapi/types.gen';
import { client as openapiClient } from 'openapi/client.gen';

import { useFirebase } from './FirebaseProvider';
import { useQueryClient } from 'react-query';
import { clearStorage } from 'util/storage';

export type AuthContextType = {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLimited: boolean;
  tokenUser: User | null;
  authUser: AuthUser | null;
  profile: Partner | null;
  login: (email: string, password: string) => Promise<UserCredential | null>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
};

const notInitiaized = () => {
  throw new Error('This feature is not yet fully initialized.');
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isAdmin: false,
  isLimited: true,
  tokenUser: null,
  authUser: null,
  profile: null,
  login: async () => {
    notInitiaized();
    return null;
  },
  logout: async () => {
    notInitiaized();
  },
  resetPassword: async () => {
    notInitiaized();
  },
});

type AuthenticationState = {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLimited: boolean;
  tokenUser: User | null;
  authUser: AuthUser | null;
  profile: Partner | null;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useFirebase();

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [authState, setAuthState] = useState<AuthenticationState>({
    isAuthenticated: false,
    isAdmin: false,
    isLimited: true,
    tokenUser: null,
    authUser: null,
    profile: null,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        openapiClient.setConfig({
          ...openapiClient.getConfig(),
          auth: () => idToken
        });

        const authUserResponse = await UsersService.usersServiceGetUser({
          path: {
            id: user.uid,
          },
        }).catch(() => null);
        const authUser = authUserResponse?.data ?? null;

        const profileResponse = await UsersService.usersServiceGetPartner({
          path: {
            id: user.uid,
          },
        }).catch(() => null);
        const profile = profileResponse?.data ?? null;

        setAuthState({
          isAuthenticated: true,
          isAdmin: authUser?.role === 'admin',
          isLimited: authUser?.role === 'hospital',
          tokenUser: user,
          authUser,
          profile,
        });
      } else {
        openapiClient.setConfig({
          ...openapiClient.getConfig(),
          auth: undefined
        });
        setAuthState({
          isAuthenticated: false,
          isAdmin: false,
          isLimited: true,
          tokenUser: null,
          authUser: null,
          profile: null,
        });
      }
      setIsMounted(true);
    });
  }, [auth]);

  const login = async (email: string, password: string) =>
    await signInWithEmailAndPassword(auth, email, password);
  const logout = async () => {
    queryClient.clear();
    clearStorage();
    await auth.signOut();
  };
  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout,
        resetPassword,
      }}>
      {isMounted && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
