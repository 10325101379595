import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { FirebaseProvider } from 'providers/FirebaseProvider';
import { AuthProvider } from 'providers/AuthProvider';
import { QueryProvider } from 'providers/QueryProvider';
import { EditStateProvider } from 'providers/EditStateProvider';

import 'assets/scss/print.scss';
import ThemeCustomization from 'theme';
import config from './config';
import App from './App';
import MaintenanceApp from './MaintenanceApp';

const maintenanceMode = config.MAINTENANCE_MODE;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FirebaseProvider>
          <QueryProvider>
            <AuthProvider>
              <SnackbarProvider>
                <ModalProvider>
                  <BrowserRouter>
                    <EditStateProvider>
                      {
                        maintenanceMode ? <MaintenanceApp /> : <App />
                      }
                    </EditStateProvider>
                  </BrowserRouter>
                </ModalProvider>
              </SnackbarProvider>
            </AuthProvider>
          </QueryProvider>
        </FirebaseProvider>
      </LocalizationProvider>
    </ThemeCustomization>
  </StrictMode>,
);
