import {
  Accordion,
  AccordionSummary,
  Button,
  Grid,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { DownloadButton } from 'components/atoms/DownloadButton';
import { useDownloadQuery } from 'hooks/useDownload';
import { DocumentsService, DocumentRef, DocumentMetadata } from 'openapi';
import { Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { DeleteDialog } from './DeleteDialog';
import { useModal } from 'react-modal-hook';
import { useDeleteDocumentMutation } from 'hooks/useDeleteDocumentMutation';
import { useAuth } from 'providers/AuthProvider';
import { useState } from 'react';
import { buttonSize } from 'util/styling';
import { getReadableFileSize } from 'util/conversions';

export interface DocumentAccordionProps {
  document: DocumentRef;
  metadata?: DocumentMetadata;
}

export const DocumentAccordion: React.FC<DocumentAccordionProps> = ({
  document,
  metadata,
}) => {
  const snackbar = useSnackbar();
  const deleteDocumentMutation = useDeleteDocumentMutation();
  const { isAdmin } = useAuth();

  const [isHidden, setIsHidden] = useState(true);
  const theme = useTheme();
  const buttonStyle = buttonSize(theme);
  const buttonDisplay: SxProps = {
    visibility: isHidden ? 'hidden' : 'visible',
  };

  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <DeleteDialog
      onConfirm={async () => {
        try {
          await Promise.all([
            deleteDocumentMutation.mutateAsync(document.fullPath),
          ]);
          snackbar.enqueueSnackbar('Document deleted successfully', {
            variant: 'success',
          });
          hideDeleteModal();
        } catch {
          snackbar.enqueueSnackbar('Failed to delete document', {
            variant: 'error',
          });
          hideDeleteModal();
        }
      }}
      onCancel={hideDeleteModal}
      itemName={'document'}
    />
  ));

  const { data, download } = useDownloadQuery(
    ['documentDownload', document],
    async () => {
      const response = await DocumentsService.documentsServiceDownloadDocument({
        body: {
          path: document.fullPath,
        }
      });
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  return (
    <Accordion
      disableGutters={true}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}>
      <AccordionSummary
        onMouseOver={() => setIsHidden(false)}
        onMouseOut={() => setIsHidden(true)}
        sx={{
          minHeight: theme.spacing(4),
          maxHeight: theme.spacing(4),
        }}>
        <Grid container alignItems="center" sx={{ margin: 1 }}>
          <Grid item xs={7}>
            <Typography variant="body2">{document.title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {metadata?.size ? getReadableFileSize(metadata.size) : ''}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {metadata?.contentType ?? ''}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <DownloadButton
              filename={document.title}
              data={data}
              download={download}
              sx={{
                ...buttonStyle,
                ...buttonDisplay,
              }}
            />
            {isAdmin && (
              <Button
                sx={{
                  ...buttonStyle,
                  ...buttonDisplay,
                }}
                onClick={() => {
                  showDeleteModal();
                }}>
                <Delete color="primary" />
              </Button>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
};
